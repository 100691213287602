.resize-wrapper {
  display: block;
  outline-offset: 1px;
  outline: 1px solid !important;
  will-change: outline-color;
  outline-color: transparent !important;
}

.show-boundary {
  outline-color: #fafafa !important;
}

.pointer-event-none {
  pointer-events: none;
}
